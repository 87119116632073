import '../styles/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LogRocket from 'logrocket';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ToastProvider } from 'react-toast-notifications';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { has, isEmpty, isObject } from 'lodash';
import urlcat from 'urlcat';
import { NavigationBar } from '../components/NavigationBar';
import WrappedStore, { persistor } from '../store';
import LoginBox from '../components/LoginBox/LoginBox';
import logger from '../utils/logger';
import ModalPage from '../components/ModalPage/ModalPage';
import { siteTitle } from '../utils/constants';
import ModalContent from '../components/ModalContent/ModalContent';
import { logoutUser, setModalContent } from '../store/actions/appActions';
import useUserInfo from '../hooks/useUserInfo';
import { isEmptyObject } from '../utils/common';
import axios from '../utils/axios';
import { getCooeeApiUrl } from '../utils/api';
import { getHostEnv } from '../utils/api';
import { getUserFullName } from '../utils/helpers';
import Link from 'next/link';
import ActiveLink from '../utils/ActiveLink';
import { COLOURS } from '../styles/Constants';

LogRocket.init('rxebcu/cooee');

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { app } = useSelector((state) => state);
  const { showLoginBox } = app;
  const dispatch = useDispatch();
  const { userInfo, refreshUserInfo } = useUserInfo();
  const baseUrl = getCooeeApiUrl(getHostEnv());

  const shouldHideNavigationBar = () => {
    let shouldHide = false;
    const pathname = router.pathname.toLowerCase();
    switch (pathname) {
      case '/meet':
        shouldHide = true;
        break;
    }
    return shouldHide;
  };

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      (async () => {
        await logger.debug(JSON.stringify(userInfo));
        await logger.info(
          `${getUserFullName(userInfo)} navigates to ${url} ${
            shallow ? 'with' : 'without'
          } shallow routing`
        );
      })();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    // console.log(showLoginBox);
    // console.log(userInfo);
    //
    // if (
    //   !showLoginBox &&
    //   userInfo &&
    //   has(userInfo, 'givenName') &&
    //   userInfo.givenName.toLowerCase() === 'new user'
    // ) {
    //   dispatch(logoutUser());
    //   console.log('bad user');
    // }

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const providerButtons = [
    {
      name: 'Disagree',
      style: {},
      action: async () => {
        try {
          await axios.delete(urlcat(baseUrl, 'provider/provider_info'));
          await refreshUserInfo();
        } catch (error) {
          await logger.error(error);
        }
      },
    },
    {
      name: 'Agree',
      style: { backgroundColor: 'red' },
      action: async () => {
        try {
          const { status, data } = await axios.post(
            urlcat(baseUrl, `provider/term`)
          );

          if (status === 200) {
            dispatch(
              setModalContent({
                show: false,
              })
            );
            await refreshUserInfo();
          }
        } catch (error) {
          await logger.error(error);
        }
      },
    },
  ];

  const generalButtons = [
    {
      name: 'Agree',
      style: { backgroundColor: 'red' },
      action: async () => {
        try {
          const { status, data } = await axios.post(
            urlcat(baseUrl, `user/term`)
          );

          if (status === 200) {
            dispatch(
              setModalContent({
                show: false,
              })
            );
            await refreshUserInfo();
          }
        } catch (error) {
          await logger.error(error);
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(
      setModalContent({
        show: false,
      })
    );

    if (isEmptyObject(userInfo)) return;

    const hasAgreedProviderTerms = has(userInfo, 'hasAgreedProviderTerms')
      ? userInfo.hasAgreedProviderTerms
      : true;
    const hasAgreedGeneralTerms = has(userInfo, 'hasAgreedGeneralTerms')
      ? userInfo.hasAgreedGeneralTerms
      : false;

    if (isObject(userInfo) && !hasAgreedGeneralTerms) {
      dispatch(
        setModalContent({
          show: true,
          // url: '/static/generalterms.html',
          url: userInfo.generalTermsUrlPath,
          buttons: generalButtons,
        })
      );
    } else if (isObject(userInfo) && !hasAgreedProviderTerms) {
      dispatch(
        setModalContent({
          show: true,
          // url: '/static/providerterms.html',
          url: userInfo.providerTermsUrlPath,
          buttons: providerButtons,
        })
      );
    }
  }, [userInfo]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <Head>
          <meta
            name="viewport"
            // content="initial-scale=0.5, width=device-width"
            content="initial-scale=1, width=device-width"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <title>{siteTitle}</title>
        </Head>
        <ToastProvider autoDismiss autoDismissTimeout={2000}>
          {!shouldHideNavigationBar() ? <NavigationBar /> : null}
          <LoginBox />
          <ModalPage />
          <ModalContent />
          <div style={{ position: 'relative', zIndex: 0 }}>
            <Component {...pageProps} />
            <div
              style={{
                color: COLOURS.lighterGrey,
                fontSize: '0.8em',
                paddingBottom: 10,
              }}
            >
              {'2023 Copyright © Cooee - '}
              <ActiveLink
                style={{
                  hover: COLOURS.lighterGrey,
                  visited: COLOURS.lighterGrey,
                  link: COLOURS.lighterGrey,
                  active: COLOURS.lighterGrey,
                }}
                href="mailto:info@oncooee.com"
              >
                Contact Us
              </ActiveLink>
              {/*{' - '}*/}
              {/*<ActiveLink*/}
              {/*  style={{*/}
              {/*    hover: COLOURS.lighterGrey,*/}
              {/*    visited: COLOURS.lighterGrey,*/}
              {/*    link: COLOURS.lighterGrey,*/}
              {/*    active: COLOURS.lighterGrey,*/}
              {/*  }}*/}
              {/*  href="#about"*/}
              {/*>*/}
              {/*  About*/}
              {/*</ActiveLink>*/}
              {/*{' - '}*/}
              {/*<ActiveLink*/}
              {/*  style={{*/}
              {/*    hover: COLOURS.lighterGrey,*/}
              {/*    visited: COLOURS.lighterGrey,*/}
              {/*    link: COLOURS.lighterGrey,*/}
              {/*    active: COLOURS.lighterGrey,*/}
              {/*  }}*/}
              {/*  href="#faq"*/}
              {/*>*/}
              {/*  FAQ*/}
              {/*</ActiveLink>*/}
            </div>
          </div>
        </ToastProvider>
      </QueryClientProvider>
    </PersistGate>
  );
}

export default WrappedStore.withRedux(MyApp);
