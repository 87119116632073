import { Modal, Alert, Form, Spinner, Button } from 'react-bootstrap';
import { HiBadgeCheck } from 'react-icons/hi';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import urlcat from 'urlcat';
import camelize from 'camelize';
import camelcaseKeys from 'camelcase-keys';
import { has, isArray, isString } from 'lodash';
import styles from './LoginBox.module.css';
import { CompanyLogo } from '../CompanyLogo';
import {
  logoutUser,
  setModalContent,
  setShowLoginBox,
  updateCurrentAppointment,
  updateUser,
  updateUserInfo,
} from '../../store/actions/appActions';
import { getCooeeApiUrl } from '../../utils/api';
import { getHostEnv } from '../../utils/api';
import logger from '../../utils/logger';
import { getUserInfo } from '../../utils/auth';
import { COLOURS } from '../../styles/Constants';
import {
  logDnaInstantAlertPrefix,
  userDefaultName,
} from '../../utils/constants';
import useUserInfo from '../../hooks/useUserInfo';
import { useQueryErrorResetBoundary } from 'react-query';
import { extractError } from '../../utils/helpers';

const LoginBox = (): JSX.Element => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { app } = useSelector((state) => state);
  const { showLoginBox } = app;
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(crypto.getRandomValues(new Uint32Array(40)));
  const [otp, setOtp] = useState('');
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, refreshUserInfo } = useUserInfo();
  const usernameInputRef = useRef();
  const otpInputRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  // const { addToast } = useToasts();

  const handleLogin = async (event) => {
    event.preventDefault();

    let response: AxiosResponse;
    const baseUrl = getCooeeApiUrl(getHostEnv());
    setIsLoading(true);
    const phoneRegExp =
      /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

    setErrorMessage('');

    if (step === 1) {
      if (
        isString(username) &&
        username.length > 10 &&
        username.match(phoneRegExp).length > 0
      ) {
        try {
          response = await axios.post(urlcat(baseUrl, 'auth/start'), {
            username,
            password: code,
          });
        } catch (error) {
          await logger.error(error);
          setIsLoading(false);
          setErrorMessage(
            error.response?.data?.error?.description || 'Unable to sign in!'
          );
          return;
        }

        const { status, data } = response;

        if (status === 200) {
          setStep(2);
          // @ts-ignore
          setTimeout(() => otpInputRef?.current?.focus(), 100);
        } else {
          const {
            error: { description },
          } = data;
          await logger.warn(
            `Login attempt with ${username} and server replied with message: ${description}`
          );
          setErrorMessage(description);
          setUsername('');
          setOtp('');
        }
      } else {
        setErrorMessage('Invalid mobile number?');
        setUsername('');
        setOtp('');
      }
    } else if (step === 2) {
      // Clear error message
      setErrorMessage('');

      try {
        response = await axios.post(urlcat(baseUrl, 'auth/token'), {
          username,
          otp,
        });
      } catch (error) {
        await logger.error(error);
        setIsLoading(false);
        setOtp('');
        setErrorMessage(
          error.response?.data?.error?.description || 'Invalid OTP?'
        );
        return;
      }

      const { status, data } = response;

      if (status === 200) {
        const user = camelize({ ...data?.data });
        dispatch(updateUser(user));

        // addToast('Login successful', { appearance: 'success' });
        const userInfo = await getUserInfo(user);
        if (userInfo) {
          const camelCaseUserInfo = camelcaseKeys(userInfo);
          dispatch(updateUserInfo(camelCaseUserInfo));

          if (
            userInfo.name.trim() === userDefaultName ||
            camelCaseUserInfo.name.length === 0
          )
            setStep(3);
          else setStep(4);

          // Clear those fields after user
          setOtp('');
          setFirstName('');
          setLastName('');
          // setUsername('');
        }
      } else {
        const {
          error: { description },
        } = data;

        // Clear those fields after user
        setOtp('');
        setFirstName('');
        setLastName('');
        setErrorMessage(description);
      }
    } else {
      setStep(1);
    }

    setIsLoading(false);
  };

  const handleBasicInfoSubmit = async (event) => {
    event.preventDefault();
    if (firstName.length === 0) {
      // @ts-ignore
      firstNameInputRef.current.focus();
      return;
    }
    if (lastName.length === 0) {
      // @ts-ignore
      lastNameInputRef.current.focus();
      return;
    }
    if (email.length === 0) {
      // @ts-ignore
      emailInputRef.current.focus();
      return;
    }

    let response: AxiosResponse;
    try {
      setErrorMessage('');
      const { data, status } = await axios.patch(urlcat(baseUrl, 'user/info'), {
        username,
        firstName,
        lastName,
        email,
      });

      setStep(4);
    } catch (error) {
      // const { request, ...errorResponse } = error?.response;
      // response = errorResponse;
      let errorMessage = 'Invalid input?';
      const errorResponse: any = extractError(error);
      if (
        has(errorResponse, 'cooeeError') &&
        has(errorResponse, 'cooeeError.context') &&
        isArray(errorResponse.cooeeError.context) &&
        errorResponse.cooeeError.context.length > 0
      ) {
        // Handle response with error + context
        errorMessage = errorResponse.cooeeError.context[0].reason;
      } else if (
        has(errorResponse, 'cooeeError') &&
        has(errorResponse, 'cooeeError.description')
      ) {
        // Handle validation errors
        // {"error":{"message":"Request has wrong format.","description":"Invalid email"}}
        errorMessage = errorResponse.cooeeError.description;
      } else if (has(errorResponse, 'cooeeError')) {
        errorMessage = errorResponse.cooeeError.message;
      }
      // await logger.error(`${logDnaInstantAlertPrefix} ${errorResponse}`);
      setErrorMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (step === 4) {
      setTimeout(() => {
        dispatch(setShowLoginBox(false));
        refreshUserInfo().catch();
      }, 2000);
      setTimeout(() => {
        setStep(1);
      }, 3000);
    }
  }, [step]);

  const handleOnHide = async () => {
    if (step !== 3) {
      dispatch(setShowLoginBox(false));
      setErrorMessage('');
      setStep(1);
      setUsername('');
      setOtp('');
      setFirstName('');
      setLastName('');
      setEmail('');

      // If user refreshes the screen whilst providing his/her user info, userInfo will not be good!
      // User will be presented with LoginBox after refreshes and he/she can click away...
      // Check if user is clicking away and force the user to log out
      if (
        userInfo &&
        has(userInfo, 'givenName') &&
        userInfo.givenName.toLowerCase() === 'new user'
      ) {
        dispatch(logoutUser());
        console.log('bad user');
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={showLoginBox}
        centered
        onHide={handleOnHide}
        onEscapeKeyDown={handleOnHide}
        contentClassName={styles.container}
        dialogClassName={styles.dialogContainer}
        // style={{ width: '100%' }}
      >
        <Modal.Body style={{ width: '90%' }}>
          {step === 3 ? (
            <>
              <h3>Tell us about yourself...</h3>
              <form onSubmit={handleBasicInfoSubmit}>
                <Form.Group controlId="formUserFirstName">
                  <Form.Label>
                    <span style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
                      First name
                    </span>
                  </Form.Label>
                  <Form.Control
                    ref={firstNameInputRef}
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder="First Name"
                  />
                </Form.Group>
                <Form.Group controlId="formUserLastName">
                  <Form.Label>
                    <span style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
                      Last name
                    </span>
                  </Form.Label>
                  <Form.Control
                    ref={lastNameInputRef}
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    placeholder="Last name"
                  />
                </Form.Group>
                <Form.Group controlId="formUserEmail">
                  <Form.Label>
                    <span style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
                      Email address
                    </span>
                  </Form.Label>
                  <Form.Control
                    ref={emailInputRef}
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    value={email}
                    placeholder="john@example.com"
                  />
                </Form.Group>
                {errorMessage.length > 0 ? (
                  <Alert key="errorMessage" variant="warning">
                    {errorMessage}
                  </Alert>
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    style={{
                      marginTop: 10,
                      width: 150,
                      backgroundColor: COLOURS.red,
                      borderColor: COLOURS.red,
                    }}
                    type="submit"
                    onSubmit={handleBasicInfoSubmit}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </>
          ) : null}
          {step === 4 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                marginTop: 150,
              }}
            >
              <HiBadgeCheck size={50} color="green" />
            </div>
          ) : null}
          {step === 1 || step === 2 ? (
            <>
              <h1
                style={{
                  margin: 20,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                <div>
                  <CompanyLogo size={100} company="Cooee" colourType="colour" />
                </div>
                <div>Sign in to Cooee</div>
              </h1>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div className={styles.loginBox}>
                  <Form onSubmit={handleLogin}>
                    {step === 1 ? (
                      <Form.Group controlId="formBasicUsername">
                        <Form.Label>
                          <span
                            style={{ fontWeight: 'bold', fontSize: '0.9em' }}
                          >
                            Mobile number (include country code)
                          </span>
                        </Form.Label>
                        <Form.Control
                          ref={usernameInputRef}
                          onFocus={() => setErrorMessage('')}
                          value={username}
                          onChange={(event) => {
                            event.target.value = event.target.value.trim();
                            if (event.target.value.length > 0) {
                              event.target.value =
                                event.target.value === '+'
                                  ? '+'
                                  : `+${event.target.value
                                      .trim()
                                      .replace(/\s/g, '')
                                      .replace(/\D/g, '')}`;
                            }

                            setUsername(event.target.value);
                          }}
                          maxLength={15}
                          placeholder="E.g. +61412345678"
                          type="tel"
                        />
                      </Form.Group>
                    ) : null}
                    {step === 2 ? (
                      <Form.Group controlId="formBasicOTP">
                        <Form.Label>
                          <span
                            style={{ fontWeight: 'bold', fontSize: '0.9em' }}
                          >
                            OTP code
                          </span>
                        </Form.Label>
                        <Form.Control
                          ref={otpInputRef}
                          value={otp}
                          onChange={(event) => {
                            event.target.value = event.target.value
                              .trim()
                              .trim()
                              .replace(/\D/g, '');

                            setOtp(event.target.value);
                          }}
                          type="tel"
                          maxLength={8}
                          placeholder="Enter OTP here"
                        />
                      </Form.Group>
                    ) : null}
                  </Form>
                </div>
              )}
              <div
                style={{
                  // width: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {errorMessage.length > 0 ? (
                  <Alert key="errorMessage" variant="warning">
                    {errorMessage}
                  </Alert>
                ) : null}
                <Button
                  style={{
                    // fontSize: '1em',
                    padding: 'auto auto',
                    // backgroundColor: COLOURS.darkGreyTransparent,
                    color: COLOURS.white,
                    borderColor: COLOURS.darkGreyTransparent,
                    borderRadius: 5,
                    backgroundColor: 'red',
                    borderStyle: 'none',
                    fontSize: '0.9em',
                  }}
                  disabled={isLoading}
                  onClick={(event) => {
                    handleLogin(event).catch();
                  }}
                >
                  <div style={{ display: 'inline-block' }}>Sign In</div>
                </Button>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
    // <div className={styles.overlay}>
    //   </div>
    // </div>
  );
};

export default LoginBox;
