import { Alert, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import urlcat from 'urlcat';
import { isString } from 'lodash';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { setModalContent } from '../../store/actions/appActions';
import axios from '../../utils/axios';
import logger from '../../utils/logger';
import { getCooeeApiUrl } from '../../utils/api';
import { getHostEnv } from '../../utils/api';
import styles from './ModalContent.module.css';
import { COLOURS } from '../../styles/Constants';

const ModalContent = () => {
  // @ts-ignore
  const { app } = useSelector((state) => state);
  const { modalContent } = app;
  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  const topRef = useRef();
  const [iframeKey, setIframeKey] = useState(0);
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const {
    show = false,
    canHide = false,
    url = '/legal/generalterms.html',
    // url = 'https://static.oncooee.com/legal/generalterms.html',
    confirmAction = () => {},
    buttons = [
      {
        name: 'Agree',
        style: { backgroundColor: 'red' },
        action: async () => {},
      },
    ],
  } = modalContent;

  useEffect(() => {
    if (!show) return;

    (async () => {
      try {
        const { data } = await axios.get(url);

        if (
          isString(url) &&
          url.length > 0 &&
          isString(data) &&
          data.length > 0
        ) {
          const purifiedData = DOMPurify.sanitize(data, {
            // FORBID_TAGS: ['a'],
            // ALLOWED_TAGS: ['a'],
            // ALLOWED_ATTR: ['target'],
          });
          // @ts-ignore
          setContent(parse(data));
        } else {
          setContent('No content available');
          await logger.warn(`No content available for ModalContent`);
        }
      } catch (err) {
        await logger.error(err);
      }
    })();
  }, [url, show]);

  const handleOnHide = () => {
    if (canHide) dispatch(setModalContent({ show: false }));
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={modalContent?.show || false}
      size="xl"
      centered
      onEntering={() => setIframeKey(iframeKey + 1)}
      onHide={handleOnHide}
      onEscapeKeyDown={handleOnHide}
      style={{ maxHeight: '100%', paddingLeft: 0, overflowY: 'hidden' }}
      contentClassName={styles.modalPageContent}
    >
      <Modal.Body>
        <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div ref={topRef} />
          {content}
        </div>
        <div
          style={{
            marginTop: 30,
            marginBottom: 30,
            // width: 300,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {buttons.map((e) => (
            <Button
              key={e.name}
              style={{
                // fontSize: '1em',
                padding: 'auto auto',
                // backgroundColor: COLOURS.darkGreyTransparent,
                color: COLOURS.white,
                borderColor: COLOURS.darkGreyTransparent,
                borderRadius: 5,
                backgroundColor: 'grey',
                borderStyle: 'none',
                fontSize: '0.9em',
                width: 200,
                ...e.style,
                marginRight: 30,
              }}
              onClick={async (event) => {
                await e.action();
                if (topRef.current) {
                  // @ts-ignore
                  topRef.current.scrollIntoView();
                }
              }}
            >
              <div style={{ display: 'inline-block' }}>{e.name}</div>
            </Button>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalContent;
