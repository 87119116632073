import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { setModalOptions } from '../../store/actions/appActions';
// import { getCooeeApiUrl, getHostEnv } from '../../utils/api';
import styles from './ModalPage.module.css';

const ModalPage = () => {
  // @ts-ignore
  const { app } = useSelector((state) => state);
  const { modalOptions } = app;
  const dispatch = useDispatch();
  const [iframeKey, setIframeKey] = useState(0);
  // const baseUrl = getCooeeApiUrl(getHostEnv());

  const handleOnHide = () => {
    dispatch(setModalOptions({ show: false }));

    // (async () => {
    //   try {
    //     await axios.get(urlcat(baseUrl, 'public/sync_appointments'));
    //   } catch (error) {
    //     await logger.error(error);
    //   }
    // })();
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={modalOptions?.show || false}
      size="xl"
      centered
      onEntering={() => setIframeKey(iframeKey + 1)}
      onHide={handleOnHide}
      onEscapeKeyDown={handleOnHide}
      style={{ maxHeight: '80vh', paddingLeft: 0 }}
      contentClassName={styles.modalPageContent}
    >
      <Modal.Body>
        <div style={{ textAlign: 'left' }}>
          <iframe
            key={iframeKey}
            src={modalOptions?.url}
            title="Modal Page"
            width="100%"
            height="800"
            frameBorder="0"
            // sandbox=""
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPage;
