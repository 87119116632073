import { useRouter } from 'next/router';
import styled from 'styled-components';
import { COLOURS } from '../styles/Constants';
import logger from './logger';

const ActiveLink = ({ children, href, style }) => {
  const router = useRouter();

  const StyledLink = styled.a`
    // color: ${({ href, path }) => (href === path ? 'white' : 'white')};
    &:visited {
      color: ${style.visited};
    }
    &:link {
      color: ${style.link};
    }
    &:hover {
      color: ${style.hover};
    }
    &:active {
      color: ${style.active};
    }
  `;

  const handleClick = (e) => {
    e.preventDefault();
    router.push(href).catch((err) => logger.error(err));
  };

  return (
    <StyledLink href={href} onClick={handleClick} currentPath={router.asPath}>
      {children}
    </StyledLink>
  );
};

export default ActiveLink;
